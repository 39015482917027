<template>
	<div class="h-100">
		<ul class="menu-nav">
			<li class="fileupload-btn d-flex justify-content-center align-items-center">
				<div class="uploadbtn">
					<div class="uploadbtn-wrapper" :class="{'is-upload-disabled': isUploadDisabled}">
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn :disabled="isUploadDisabled" v-bind="attrs" v-on="on" tile outlined class="text-center text-white py-2 pt-5">
									<v-icon dark style="font-size: 50px; color: #fff !important;" class=""> mdi-cloud-upload </v-icon>
									<div class="btn-text">Upload</div>
								</v-btn>
							</template>
							<v-list dense class="upload-option-list">
								<v-list-item v-on:click="upload_dialog('new-folder')">
									<v-list-item-icon>
										<v-icon>mdi-folder-plus-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title><span class="font-level-3-bold">New Folder</span></v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="my-2"></v-divider>
								<v-list-item v-on:click="upload_dialog('file-upload')">
									<v-list-item-icon>
										<v-icon>mdi-file-upload-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title><span class="font-level-3-bold">File Upload</span></v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-on:click="upload_dialog('folder-upload')">
									<v-list-item-icon>
										<v-icon>mdi-folder-upload-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title
											><span class="font-level-3-bold">Folder Upload</span></v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-menu>
					</div>
				</div>
				<span class="menu-tooltips">Upload</span>
			</li>
			<router-link
				:to="{ name: 'dashboard', query: { t: new Date().getTime() } }"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					aria-haspopup="true"
					data-menu-toggle="hover"
					class="menu-item"
					:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
				>
					<a :href="href" class="menu-link" @click="navigate">
						<span class="menu-svg-icon mr-4">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/dashboard.svg')" />
							<!--end::Svg Icon-->
						</span>
						<span class="menu-text">Dashboard</span>
					</a>
					<span class="menu-tooltips">Dashboard</span>
				</li>
			</router-link>
			<router-link
				:to="{ name: 'mine', query: { t: new Date().getTime() } }"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					aria-haspopup="true"
					data-menu-toggle="hover"
					class="menu-item"
					:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
				>
					<a :href="href" class="menu-link" @click="navigate">
						<span class="menu-svg-icon mr-4">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/drive.svg')" />
							<!--end::Svg Icon-->
						</span>
						<span class="menu-text">Drive</span>
					</a>
					<span class="menu-tooltips">Drive</span>
				</li>
			</router-link>
			<router-link
				:to="{ name: 'shared-with-me', query: { t: new Date().getTime() } }"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					aria-haspopup="true"
					data-menu-toggle="hover"
					class="menu-item"
					:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
				>
					<a :href="href" class="menu-link" @click="navigate">
						<span class="menu-svg-icon mr-4">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/users.svg')" />
							<!--end::Svg Icon-->
						</span>
						<span class="menu-text">Shared with me</span>
					</a>
					<span class="menu-tooltips">Shared with me</span>
				</li>
			</router-link>
			<router-link
				:to="{ name: 'recent', query: { t: new Date().getTime() } }"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					aria-haspopup="true"
					data-menu-toggle="hover"
					class="menu-item"
					:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
				>
					<a :href="href" class="menu-link" @click="navigate">
						<span class="menu-svg-icon mr-4">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/clock.svg')" />
							<!--end::Svg Icon-->
						</span>
						<span class="menu-text">Recent</span>
					</a>
					<span class="menu-tooltips">Recent</span>
				</li>
			</router-link>
			<router-link
				:to="{ name: 'starred', query: { t: new Date().getTime() } }"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					aria-haspopup="true"
					data-menu-toggle="hover"
					class="menu-item"
					:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
				>
					<a :href="href" class="menu-link" @click="navigate">
						<span class="menu-svg-icon mr-4">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/star.svg')" />
							<!--end::Svg Icon-->
						</span>
						<span class="menu-text">Starred</span>
					</a>
					<span class="menu-tooltips">Starred</span>
				</li>
			</router-link>
			<router-link
				:to="{ name: 'bin', query: { t: new Date().getTime() } }"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					aria-haspopup="true"
					data-menu-toggle="hover"
					class="menu-item"
					:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
				>
					<a :href="href" class="menu-link" @click="navigate">
						<span class="menu-svg-icon mr-4">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/trash.svg')" />
							<!--end::Svg Icon-->
						</span>
						<span class="menu-text">Bin</span>
					</a>
					<span class="menu-tooltips">Bin</span>
				</li>
			</router-link>
			<router-link
				:to="{ name: 'tag', query: { t: new Date().getTime() } }"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					aria-haspopup="true"
					data-menu-toggle="hover"
					class="menu-item"
					:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
				>
					<a :href="href" class="menu-link" @click="navigate">
						<span class="menu-svg-icon mr-4">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/tags-solid.svg')" />
							<!--end::Svg Icon-->
						</span>
						<span class="menu-text">Tag</span>
					</a>
					<span class="menu-tooltips">Tag</span>
				</li>
			</router-link>
			<router-link
				:to="{ name: 'Users', query: { t: new Date().getTime() } }"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					aria-haspopup="true"
					data-menu-toggle="hover"
					class="menu-item"
					:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
				>
					<a :href="href" class="menu-link" @click="navigate">
						<span class="menu-svg-icon mr-4">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/users.svg')" />
							<!--end::Svg Icon-->
						</span>
						<span class="menu-text">Users</span>
					</a>
					<span class="menu-tooltips">Users</span>
				</li>
			</router-link>
			<router-link
				:to="{ name: 'activity', query: { t: new Date().getTime() } }"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					aria-haspopup="true"
					data-menu-toggle="hover"
					class="menu-item"
					:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
				>
					<a :href="href" class="menu-link" @click="navigate">
						<span class="menu-svg-icon mr-4">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/group.svg')" />
							<!--end::Svg Icon-->
						</span>
						<span class="menu-text">Activity Log</span>
					</a>
					<span class="menu-tooltips">Activity Log</span>
				</li>
			</router-link>
			<template v-if="false">
				<router-link
					:to="{ name: 'clients', query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/member.svg')" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">Clients</span>
						</a>
						<span class="menu-tooltips">Clients</span>
					</li>
				</router-link>
			</template>
			<router-link
				v-if="false"
				:to="{ name: 'Setting', query: { t: new Date().getTime() } }"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					aria-haspopup="true"
					data-menu-toggle="hover"
					class="menu-item"
					:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
				>
					<a :href="href" class="menu-link" @click="navigate">
						<span class="menu-svg-icon mr-4">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/user-solid.svg')" />
							<!--end::Svg Icon-->
						</span>
						<span class="menu-text">My Account</span>
					</a>
					<span class="menu-tooltips">My Account</span>
				</li>
			</router-link>
		</ul>
		<div v-if="!loading" class="storage_status">
			<div class="d-flex align-items-center text-white mb-2">
				<v-icon class="mr-3">mdi-cloud</v-icon>
				<span>Storage</span>
			</div>
			<div class="storage-bar">
				<div class="progress" style="height: 5px">
					<div
						class="progress-bar"
						role="progressbar"
						:style="`width: ${used_percentage}%`"
						:aria-valuenow="used_percentage"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
				<div class="my-2 text-white">{{ used_storage }} of {{ allocated_storage }} used</div>
			</div>
			<button v-if="false" class="btn btn-primary">Buy Storage</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/core/event-bus/event.bus";
import { QUERY } from "@/core/services/store/request.module";
import ObjectPath from "object-path";

export default {
	name: "KTMenu",
	data() {
		return {
			loading: false,
			used_storage: null,
			allocated_storage: null,
			used_percentage: 0,
		};
	},
	methods: {
		upload_dialog(param) {
			if(this.canUpload){
				EventBus.$emit("upload-dialog", param);
			}
		},
		hasActiveChildren(match) {
			return this.$route["path"].indexOf(match) !== -1;
		},
		init_storage() {
			this.loading = true;
			this.$store
				.dispatch(QUERY, { url: `account/storage?t=${new Date().getTime()}` })
				.then((response) => {
					this.used_storage = ObjectPath.get(response, "used-storage-formatted", null);
					this.allocated_storage = ObjectPath.get(response, "allocated-storage-formatted", null);
					this.used_percentage = ObjectPath.get(response, "used-percentage", 0);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	beforeDestroy() {
		EventBus.$off("refresh-storage");
	},
	computed: {
		...mapGetters(["canUpload","isUploadDisabled"]),
	},
	mounted() {
		this.init_storage();

		EventBus.$on("refresh-storage", () => {
			this.init_storage();
		});
	},
};
</script>
