var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[_c('ul',{staticClass:"menu-nav"},[_c('li',{staticClass:"fileupload-btn d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"uploadbtn"},[_c('div',{staticClass:"uploadbtn-wrapper",class:{'is-upload-disabled': _vm.isUploadDisabled}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-center text-white py-2 pt-5",attrs:{"disabled":_vm.isUploadDisabled,"tile":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"50px","color":"#fff !important"},attrs:{"dark":""}},[_vm._v(" mdi-cloud-upload ")]),_c('div',{staticClass:"btn-text"},[_vm._v("Upload")])],1)]}}])},[_c('v-list',{staticClass:"upload-option-list",attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.upload_dialog('new-folder')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-folder-plus-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"font-level-3-bold"},[_vm._v("New Folder")])])],1)],1),_c('v-divider',{staticClass:"my-2"}),_c('v-list-item',{on:{"click":function($event){return _vm.upload_dialog('file-upload')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-upload-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"font-level-3-bold"},[_vm._v("File Upload")])])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.upload_dialog('folder-upload')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-folder-upload-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"font-level-3-bold"},[_vm._v("Folder Upload")])])],1)],1)],1)],1)],1)]),_c('span',{staticClass:"menu-tooltips"},[_vm._v("Upload")])]),_c('router-link',{attrs:{"to":{ name: 'dashboard', query: { t: new Date().getTime() } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/dashboard.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])]),_c('span',{staticClass:"menu-tooltips"},[_vm._v("Dashboard")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'mine', query: { t: new Date().getTime() } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/drive.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Drive")])]),_c('span',{staticClass:"menu-tooltips"},[_vm._v("Drive")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'shared-with-me', query: { t: new Date().getTime() } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/users.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Shared with me")])]),_c('span',{staticClass:"menu-tooltips"},[_vm._v("Shared with me")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'recent', query: { t: new Date().getTime() } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/clock.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Recent")])]),_c('span',{staticClass:"menu-tooltips"},[_vm._v("Recent")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'starred', query: { t: new Date().getTime() } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/star.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Starred")])]),_c('span',{staticClass:"menu-tooltips"},[_vm._v("Starred")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'bin', query: { t: new Date().getTime() } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/trash.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Bin")])]),_c('span',{staticClass:"menu-tooltips"},[_vm._v("Bin")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'tag', query: { t: new Date().getTime() } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/tags-solid.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Tag")])]),_c('span',{staticClass:"menu-tooltips"},[_vm._v("Tag")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'Users', query: { t: new Date().getTime() } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/users.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Users")])]),_c('span',{staticClass:"menu-tooltips"},[_vm._v("Users")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'activity', query: { t: new Date().getTime() } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/group.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Activity Log")])]),_c('span',{staticClass:"menu-tooltips"},[_vm._v("Activity Log")])])]}}])}),(false)?[_c('router-link',{attrs:{"to":{ name: 'clients', query: { t: new Date().getTime() } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/member.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Clients")])]),_c('span',{staticClass:"menu-tooltips"},[_vm._v("Clients")])])]}}],null,false,1784410718)})]:_vm._e(),(false)?_c('router-link',{attrs:{"to":{ name: 'Setting', query: { t: new Date().getTime() } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/user-solid.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("My Account")])]),_c('span',{staticClass:"menu-tooltips"},[_vm._v("My Account")])])]}}],null,false,2807758895)}):_vm._e()],2),(!_vm.loading)?_c('div',{staticClass:"storage_status"},[_c('div',{staticClass:"d-flex align-items-center text-white mb-2"},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-cloud")]),_c('span',[_vm._v("Storage")])],1),_c('div',{staticClass:"storage-bar"},[_c('div',{staticClass:"progress",staticStyle:{"height":"5px"}},[_c('div',{staticClass:"progress-bar",style:(("width: " + _vm.used_percentage + "%")),attrs:{"role":"progressbar","aria-valuenow":_vm.used_percentage,"aria-valuemin":"0","aria-valuemax":"100"}})]),_c('div',{staticClass:"my-2 text-white"},[_vm._v(_vm._s(_vm.used_storage)+" of "+_vm._s(_vm.allocated_storage)+" used")])]),(false)?_c('button',{staticClass:"btn btn-primary"},[_vm._v("Buy Storage")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }